@import "../../assets/scss/global/variables";

.chart {
  border-radius: 0.25rem;
}

.personA {
  stroke: $color-red;
}
.personB {
  stroke: $color-green;
}
