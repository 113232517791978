/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

tr {
  border-bottom: solid thin;

  &:last-child {
    border-bottom: none;
  }
}

td,
th {
  vertical-align: top;
  padding: 0.5em;
}
