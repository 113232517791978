@import "../global/variables";
@import "../global/accessibility";
@import "../typography/headings";

@mixin focusRing {
  outline: solid $color-blue-light;
  outline-offset: 0.25em;
}

.form {
  background-color: white;
}

.section {
  padding: 0;
  border: none;
  margin: 2rem 0;
  &:first-child() {
    margin: 0 0 2rem;
  }
}

legend.sectionHeader,
.sectionHeader {
  @include bigHeading;
  display: block;
  margin-bottom: 2rem;
}

.sectionIntro {
  margin: 0 0 2rem;
}

.radioList,
.checkboxList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.radioItem,
.checkboxItem {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: baseline;

  &:focus-within {
    @include focusRing;
  }
  input {
    outline: none;
    margin-right: 0.5em;
  }

  .label {
    flex-grow: 1;
    font-weight: bold;
  }
  .help {
    font-size: smaller;
    font-style: italic;
  }
}

.radioItem + .radioItem,
.checkboxItem + .checkboxItem {
  margin-top: 0.5em;
}

.questionWrapper {
  padding-bottom: 1em;
}

.question {
  display: block;
  font-weight: 700;
  margin-bottom: 0.5em;
}
.questionHelp {
  background-color: $color-buff;
  border-left: solid 0.5em $color-yellow;
  font-size: smaller;
  font-style: italic;
  padding: 0.5em 1em;
  margin: 1em 0;
}

.radioList label,
.checkboxList label {
  font-weight: 500;
}

.inputField {
  border: solid thin;
  padding: 0.5em;
}

textarea.inputField {
  font-family: inherit;
  font-size: 1rem;
  width: 100%;
  min-height: 8em;
  resize: vertical;
}

.symptoms {
  list-style: none;
  padding: 0;
}

.symptomProfile {
  border: none;
  outline: none;
  padding: 0;
}

.symptomProfile:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.symptomProfileList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sliderLabel {
  display: block;
}

// pagination
.pagination {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
}
a.prev,
a.next,
.prev,
.next,
.submit {
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  background-color: $color-green;
  color: white;
  padding: 0.75em 2em;
  border: none;

  &:disabled {
    background-color: $color-grey-light;
    cursor: not-allowed;
  }

  &:focus-visible {
    @include focusRing;
  }
}

.prev {
  grid-column: 1;
}
.submit,
.next {
  grid-column: 2;
}
