@import "../assets/scss/forms/forms";

.questions {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.add {
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  background-color: $color-green;
  color: white;
  padding: 0.75em 2em;
  border: none;
}
