@import "./assets/scss/global/mixins/";
@import "./assets/scss/global/variables";

.wrapper {
  background-color: $color-green-dark;
}

.header {
  padding: 1em;
  background-color: $color-green;
  color: white;
}

.headerTitle {
  font-size: 1rem;
  color: white;
  a {
    color: currentColor;
  }
}
.headerLogo {
  display: block;
  height: 3em;
  width: 12em;
  fill: currentColor;
}
