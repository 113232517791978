@import "../assets/scss/typography/tables";

.responses {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.table {
  table-layout: auto;
  width: 100%;
}

.cellNumber {
  text-align: right;
}

.thTitle {
  margin: 0;
}

.thDescription {
  font-weight: normal;
  font-size: small;
  max-width: 40em;
}
