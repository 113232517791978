@import "../assets/scss/global/variables";

.progress {
  transition-delay: 0.2s;
  transition: 0.5s;
  stroke: $color-green;
}

.current {
  stroke: $color-yellow;
}
