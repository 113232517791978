@import "../assets/scss/global/variables";

.options {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.option {
  margin-right: 1em;
  padding-right: 0.5em;

  &:focus-within {
    outline: solid $color-blue-light;
    outline-offset: 0.25em;
  }

  input {
    margin-right: 0.5em;
    outline: none;
  }
}
