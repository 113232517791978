@import "../assets/scss/typography/index";

.pageTitle {
  @include bigHeading;
}
.bigButton,
a.bigButton {
  background-color: $color-green;
  color: white;
  display: block;
  text-decoration: none;
  text-align: center;
  padding: 0.5em 1em;
}

.links {
  list-style: none;
  padding: 0;
  display: grid;
  gap: 1em;
}

.definitions {
  dt {
    color: $color-green-dark;
    font-weight: bold;
    font-size: large;
  }
  dd {
    margin: 0 0 1em 0;
  }
}
