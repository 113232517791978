@mixin padder {
  padding: 1rem;
  @media (min-width: 60rem) {
    padding: 2rem;
  }
}

@mixin gutter {
  gap: 1rem;
  @media (min-width: 60rem) {
    gap: 2rem;
  }
}
