@import "../assets/scss/global/variables";

.wrapper {
  display: flex;
  padding: 0.5em 0.5em 0 0.5em;
}

.link {
  display: block;
  border: none;
  background-color: transparent;
  line-height: 1.5;
  color: white;
  text-decoration: none;
  padding: 0.25em 1em;
}
.active {
  @extend .link;
  background-color: $color-green;
}
