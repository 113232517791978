// Fonts
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap");

$font-header: "Lilita One", sans-serif;
$font-body: "Open Sans", sans-serif;

// Colors
$color-green: #19ab93;
$color-yellow: #f2d865;
$color-orange: #dd9639;
$color-red: #e56c5b;
$color-pink: #f5c4bd;
$color-blue: #30549c;
$color-blue-light: #0098ce;
$color-purple: #6c48b3;
$color-buff: #f2ece6;
$color-black: #231f20;
$color-grey: #8f8f8f;

$color-grey-light: #cccbcb;
$color-green-dark: #168975;
