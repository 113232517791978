@import "../global/variables";
@import "../global/mixins/index";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2rem 0 1rem;
  font-weight: normal;
  line-height: 1.4;
  &:first-child {
    margin-top: 0;
  }
  a {
    color: currentColor;
  }
}

@mixin bigHeading {
  @include fluid-type(1.5rem, 2.5rem, 30em, 80em);
  color: $color-green;
  font-family: $font-header;
  text-transform: uppercase;
  line-height: 1.2;
}

.bigHeading {
  @include bigHeading();
}
