@import "variables";

html {
  font-family: $font-body;
  background-color: $color-buff;
  line-height: 1.8;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  box-sizing: border-box;
  font-size: calc(1em + 0.2 * ((100vw - 30em) / 50));
}

body {
  padding: 0;
  margin: 0;
}

// box-sizing fix
*,
*:before,
*:after {
  box-sizing: inherit;
}

@import "../typography/index";
@import "../forms/reset";
@import "accessibility";
