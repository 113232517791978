@import "../../assets/scss/global/accessibility";
@import "../../assets/scss/global/variables";
@import "../../assets/scss/forms/forms";

.question {
  padding: 1em 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    padding: 0;
  }
  tr {
    border: none;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
}
.head {
  text-align: center;
}

.labelsCell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionLabels {
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  line-height: 1.4;
  text-align: center;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
}
.optionRadios {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  align-items: center;
}
.option {
  display: block;
  margin: 0;

  background-color: white;
  align-items: center;

  label {
    border: solid thin white;
    cursor: pointer;
    display: block;
    margin: 0;
    width: 100%;
    padding: 1em;
    position: relative;
    z-index: 0;

    &:focus-within {
      z-index: 1;
      @include focusRing;
    }
  }
  input {
    @extend .screen-reader-text;
  }
}

.inactive {
  background-color: $color-buff;
}

.active {
  background-color: $color-green;
  color: white;
  position: relative;
  z-index: 1;
}
