@import "./assets/scss/global/mixins/index";

.ContentWrapper {
  @include padder;
}
.Content {
  @include padder;
  background-color: white;
  max-width: 50rem;
  margin: 0 auto;
}
